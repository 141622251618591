import { render, staticRenderFns } from "./RadioInput.vue?vue&type=template&id=6c0a0e9c&scoped=true&"
import script from "./RadioInput.vue?vue&type=script&lang=js&"
export * from "./RadioInput.vue?vue&type=script&lang=js&"
import style0 from "./RadioInput.vue?vue&type=style&index=0&id=6c0a0e9c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0a0e9c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/runner/work/business-cards/business-cards/ola/Z.Aspiring.Ola/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib'
installComponents(component, {VRadio})
