<template>
  <tooltip top slot="label">
    <a slot="activator" class="activator" href="#" @click.prevent
      >AdBlue</a
    >
    <div class="tooltip-content">
      <typography type="small-p" bold no-margin
        >Diesel Exhaust Fluid</typography
      >
    </div>
  </tooltip>
</template>

<script>
import Tooltip from '@/components/atoms/Tooltip.vue';
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography,
    Tooltip
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.tooltip-content {
  color: $charcoal;
  text-align: center;
}

.tooltip-note {
  color: $note-color;
}
.activator {
  text-decoration: underline dotted;
}
</style>
