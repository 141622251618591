<template>
  <div class="radio-wrapper">
    <v-radio
      :label="label"
      :value="value"
      class="radio-input"
      @change="onChange"
      :name="name"
    >
      <div v-if="!!$slots.label" slot="label">
        <slot name="label" />
      </div>
    </v-radio>
    <typography class="radio-hint" type="small-p">{{ hint }}</typography>
    <div v-if="!!$slots.default" class="radio-content">
      <slot />
    </div>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: null
    },
    hint: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.radio-input {
  margin-bottom: 0;

  /deep/ .v-label {
    font-size: rem(20px);
    color: $charcoal;
  }

  /deep/ .v-icon {
    color: $mid-charcoal;

    &.accent--text {
      color: $bach-blue;
    }
  }

  /deep/ .v-input--selection-controls__input {
    margin: rem(0 20px 0 0);
  }
}

.radio-content {
  padding-left: rem(45px);
  margin-top: rem(40px);
}

.radio-wrapper {
  padding: rem(20px 0);
  border-bottom: 1px solid $light-charcoal;

  .radio-hint {
    margin-left: rem(45px);
    margin-bottom: 0;
    color: $note-color;
  }
}
</style>
