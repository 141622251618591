import { render, staticRenderFns } from "./SwitchInput.vue?vue&type=template&id=06276049&scoped=true&"
import script from "./SwitchInput.vue?vue&type=script&lang=js&"
export * from "./SwitchInput.vue?vue&type=script&lang=js&"
import style0 from "./SwitchInput.vue?vue&type=style&index=0&id=06276049&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06276049",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/runner/work/business-cards/business-cards/ola/Z.Aspiring.Ola/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib'
installComponents(component, {VSwitch})
