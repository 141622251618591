<template>
  <div v-if="activeCard" :key="activeCardIndex" class="preferences">
    <header
      :class="[
        'preferences__header',
        { 'preferences__header--sidebar-open': cardPreferencesSidebarOpen }
      ]"
    >
      <icon-button
        v-if="$vuetify.breakpoint.mdAndDown"
        class="back-button"
        @click="onBack"
      >
        <back-icon />
      </icon-button>
      <v-container>
        <div class="preferences__header-content">
          <v-layout justify-space-between wrap>
            <!-- Card preferences title -->
            <v-flex v-if="$vuetify.breakpoint.smAndUp" xs12 md4 mb-3>
              <typography type="tag" class="preferences-title" no-margin
                >{{ brand }} fuel card preferences</typography
              >
            </v-flex>
            <!-- Card actions -->
            <v-flex xs12 md8>
              <div class="card-actions">
                <text-button
                  :disabled="numCards <= 1"
                  :color="$vuetify.breakpoint.xsOnly ? 'orange' : 'dark-blue'"
                  always-underline
                  @click="onApplyToAll"
                  name="cardActionsApplyToAll"
                  >Apply to all cards</text-button
                >
                <text-button
                  :disabled="!activeCardHasChanged"
                  :color="$vuetify.breakpoint.xsOnly ? 'orange' : 'dark-blue'"
                  always-underline
                  @click="onDiscardChanges"
                  name="cardActionsDiscardChanges"
                  >Discard changes</text-button
                >
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-container>
    </header>

    <!-- Main content -->
    <v-layout>
      <v-flex sm11>
        <v-container>
          <v-layout wrap justify-center>
            <v-flex xs8 sm12 mt-3>
              <typography
                :align="$vuetify.breakpoint.xsOnly ? 'center' : 'left'"
                type="h5"
                light
                >Tailor this {{ brand }} fuel card to suit your needs.</typography
              >
            </v-flex>
          </v-layout>

          <!-- Card type fields -->
          <v-layout :justify-center="$vuetify.breakpoint.mdAndDown" wrap>
            <v-flex xs12 md3>
              <typography type="h4" class="group-label">Card type</typography>
            </v-flex>
            <v-flex xs12 md7>
              <v-radio-group v-model="cardType" class="radio-group">
                <radio-input
                  :value="cardTypeAnyValue"
                  label="Any driver, any vehicle"
                  name="cardTypeAnyDriverAnyVehicle"
                />

                <radio-input
                  :value="cardTypeVehicleValue"
                  label="Vehicle"
                  hint="Specific to a vehicle, can be used by any driver to fill the vehicle."
                  name="cardTypeVehicle"
                >
                  <div v-if="cardType === cardTypeVehicleValue">
                    <text-input
                      v-model="vehicleRegistration"
                      :id="vehicleRegistrationId"
                      :error-messages="vehicleRegistrationErrors"
                      label="Vehicle registration"
                      @blur="$v.vehicleRegistration.$touch()"
                      name="cardTypeVehicleRegistration"
                      :maxlength="6"
                    />
                  </div>
                </radio-input>
                <radio-input
                  :value="cardTypeDriverValue"
                  label="Driver"
                  hint="Specific to a driver, can be used to fill up any vehicle."
                  name="cardTypeDriver"
                >
                  <div v-if="cardType === cardTypeDriverValue">
                    <text-input
                      v-model="driverName"
                      :id="driverNameId"
                      :error-messages="driverNameErrors"
                      label="Driver's full name"
                      @blur="$v.driverName.$touch()"
                      name="cardTypeDriverName"
                      :maxlength="21"
                    />
                  </div>
                </radio-input>
                <radio-input
                  :value="cardTypeDriverVehicleValue"
                  label="Specific driver, specific vehicle"
                  hint="Specific to a driver, can be used to fill up the specific vehicle."
                  name="cardTypeSpecificDriverSpecificVehicle"
                >
                  <div v-if="cardType === cardTypeDriverVehicleValue">
                    <text-input
                      v-model="driverVehicleName"
                      :id="driverVehicleNameId"
                      :error-messages="driverVehicleNameErrors"
                      label="Driver's full name"
                      @blur="$v.driverVehicleName.$touch()"
                      name="cardTypeDriverName"
                      :maxlength="21"
                    />
                    <text-input
                      v-model="driverVehicleRegistration"
                      :id="driverVehicleRegistrationId"
                      :error-messages="driverVehicleRegistrationErrors"
                      label="Vehicle registration"
                      @blur="$v.driverVehicleRegistration.$touch()"
                      name="cardTypeVehicleRegistration"
                      :maxlength="6"
                    />
                  </div>
                </radio-input>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>

        <!-- Product type fields -->
        <v-container>
          <v-layout :justify-center="$vuetify.breakpoint.mdAndDown" wrap>
            <v-flex xs12 md3>
              <typography type="h4" class="group-label"
                >Which products would you like to enable on the
                card?</typography
              >
            </v-flex>
            <v-flex xs12 md7>
              <v-radio-group v-model="products" class="radio-group">
                <radio-input
                  v-for="product in productsOptions"
                  :value="product.value"
                  v-bind:key="product.value"
                  v-bind:name="
                    'product' +
                      product.value[0].toUpperCase() +
                      product.value.slice(1)
                  "
                >
                  <div slot="label">
                    <template v-for="(node, n) in labelNodes(product.label)">
                      <def-tooltip
                        v-if="isNodeLabelDEF(node)"
                        v-bind:key="node + n"
                      />
                      <template v-else
                        >{{ node }}&nbsp;</template
                      >
                    </template>
                  </div>
                </radio-input>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>

        <!-- Spending limits -->
        <v-container grid-list-xl>
          <v-layout :justify-center="$vuetify.breakpoint.smAndDown" wrap>
            <v-flex xs12 md3>
              <typography type="h4">Spend limit</typography>
            </v-flex>
            <v-flex xs6 md4 lg3>
              <select-input
                v-model="monthlyLimit"
                :id="monthlyLimitId"
                :items="monthyCardLimits"
                label="Monthly limit"
                item-value="value"
                item-text="label"
                :error-messages="monthlyLimitErrors"
                name="spendLimitMonthly"
                @change="$v.monthlyLimit.$touch()"
              />
            </v-flex>
            <v-flex xs6 md4 lg3>
              <select-input
                v-model="dailyLimit"
                :id="dailyLimitId"
                :items="dailyCardLimits"
                label="Daily limit"
                item-value="value"
                item-text="label"
                :error-messages="dailyLimitErrors"
                name="spendLimitDaily"
                @change="$v.dailyLimit.$touch()"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <!-- Cost note -->
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import TextButton from '@/components/atoms/TextButton.vue';
import SwitchInput from '@/components/atoms/SwitchInput.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RadioInput from '@/components/atoms/RadioInput.vue';
import IconButton from '@/components/atoms/IconButton.vue';
import TooManyCardsDialog from '@/components/organisms/TooManyCardsDialog.vue';
import BackIcon from '@/assets/icons/back.svg';
import DefTooltip from '@/components/molecules/DefTooltip.vue';
import SelectInput from '@/components/atoms/SelectInput.vue';

import {
  CARD_TYPE_ANY,
  CARD_TYPE_VEHICLE,
  CARD_TYPE_DRIVER,
  CARD_TYPE_DRIVER_VEHICLE,
  BRAND,
  DAILY_CARD_LIMITS,
  MONTHLY_CARD_LIMITS
} from '@/constants/form';
import {
  DAILY_LIMIT,
  MONTHLY_LIMIT,
  VEHICLE_REGISTRATION,
  DRIVER_NAME
} from '@/constants/html-ids';
import { mapState, mapGetters } from 'vuex';
import {
  requiredIf,
  alphaNum,
  maxLength,
  minValue
} from 'vuelidate/lib/validators';
import { navigationOffset } from '@/helpers/navigation';
import { PRODUCTS_OPTIONS } from '@/store/modules/cardPreferences';
import { onlyDigits } from '@/validators';

const DECIMAL_CHARACTER_CODE = 46;

const VALIDATION_MESSAGES = {
  REQUIRED: 'This field is required',
  ALPHA_NUM: 'Only letters and numbers are allowed',
  MAX_LENGTH: 'This field exceeds the maximum number of characters',
  GREATER_THAN_0: 'Limit must be greater than 0',
  ONLY_DIGITS: 'Limit should only be numbers',
  DAILY_CANNOT_EXCEED_MONTHLY_LIMIT:
    'Daily limit cannot exceed the monthly limit',
  OVER_COMPANY_MONTHLY_SPENDING: 'Monthly limit exceeds company spending'
};

export default {
  components: {
    Typography,
    TextButton,
    SwitchInput,
    TextInput,
    RadioInput,
    IconButton,
    BackIcon,
    TooManyCardsDialog,
    DefTooltip,
    SelectInput
  },
  data() {
    return {
      productsOptions: PRODUCTS_OPTIONS,
      monthyCardLimits: MONTHLY_CARD_LIMITS,
      dailyCardLimits: DAILY_CARD_LIMITS
    };
  },
  computed: {
    dailyLimit: {
      get() {
        return this.activeCard.limits.daily;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeDailyLimit', value);
      }
    },
    monthlyLimit: {
      get() {
        return this.activeCard.limits.monthly;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeMonthlyLimit', value);
      }
    },
    cardType: {
      get() {
        return this.activeCard.cardType;
      },
      set(value) {
        // Reset dirty states when changing to the type and input
        // values that aren't part of the card type
        switch (value) {
          case CARD_TYPE_VEHICLE:
            this.$v.vehicleRegistration.$reset();

            this.driverName = '';
            break;
          case CARD_TYPE_DRIVER:
            this.$v.driverName.$reset();

            this.vehicleRegistration = '';
            break;
          case CARD_TYPE_DRIVER_VEHICLE:
            this.$v.driverVehicleName.$reset();
            this.$v.driverVehicleRegistration.$reset();

            break;
          default:
            this.driverName = '';
            this.vehicleRegistration = '';
            break;
        }

        this.$store.dispatch('cardPreferences/changeCardType', value);
      }
    },
    vehicleRegistration: {
      get() {
        return this.activeCard.vehicle.registration;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeVehicle', {
          key: 'registration',
          value
        });
      }
    },
    driverName: {
      get() {
        return this.activeCard.driver.name;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeDriverName', value);
      }
    },
    driverVehicleName: {
      get() {
        return this.activeCard.driver.name;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeDriverName', value);
      }
    },
    driverVehicleRegistration: {
      get() {
        return this.activeCard.vehicle.registration;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeVehicle', {
          key: 'registration',
          value
        });
      }
    },
    products: {
      get() {
        return this.activeCard.products;
      },
      set(value) {
        this.$store.dispatch('cardPreferences/changeProduct', value);
      }
    },
    cardPreferencesSidebarOpen() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return true;
      }

      return this.$store.state.ui.cardPreferencesSidebarOpen;
    },
    vehicleRegistrationErrors() {
      let errors = [];

      if (!this.$v.vehicleRegistration.$dirty) {
        return [];
      }

      if (!this.$v.vehicleRegistration.required) {
        errors = [...errors, VALIDATION_MESSAGES.REQUIRED];
      }

      if (!this.$v.vehicleRegistration.alphaNum) {
        errors = [...errors, VALIDATION_MESSAGES.ALPHA_NUM];
      }

      if (!this.$v.vehicleRegistration.maxLength) {
        errors = [...errors, VALIDATION_MESSAGES.MAX_LENGTH];
      }

      return errors;
    },
    driverNameErrors() {
      let errors = [];

      if (!this.$v.driverName.$dirty) {
        return [];
      }

      if (!this.$v.driverName.required) {
        errors = [...errors, VALIDATION_MESSAGES.REQUIRED];
      }

      if (!this.$v.driverName.maxLength) {
        errors = [...errors, VALIDATION_MESSAGES.MAX_LENGTH];
      }

      return errors;
    },
    driverVehicleNameErrors() {
      let errors = [];

      if (!this.$v.driverVehicleName.$dirty) {
        return [];
      }

      if (!this.$v.driverVehicleName.required) {
        errors = [...errors, VALIDATION_MESSAGES.REQUIRED];
      }

      if (!this.$v.driverVehicleName.maxLength) {
        errors = [...errors, VALIDATION_MESSAGES.MAX_LENGTH];
      }

      return errors;
    },
    driverVehicleRegistrationErrors() {
      let errors = [];

      if (!this.$v.driverVehicleRegistration.$dirty) {
        return [];
      }

      if (!this.$v.driverVehicleRegistration.required) {
        errors = [...errors, VALIDATION_MESSAGES.REQUIRED];
      }

      if (!this.$v.driverVehicleRegistration.alphaNum) {
        errors = [...errors, VALIDATION_MESSAGES.ALPHA_NUM];
      }

      if (!this.$v.driverVehicleRegistration.maxLength) {
        errors = [...errors, VALIDATION_MESSAGES.MAX_LENGTH];
      }

      return errors;
    },
    dailyLimitErrors() {
      let errors = [];
      if (!this.$v.dailyLimit.$dirty) {
        return [];
      }

      if (!this.$v.dailyLimit.onlyDigits) {
        errors = [...errors, VALIDATION_MESSAGES.ONLY_DIGITS];
      }

      if (!this.$v.dailyLimit.minValue) {
        errors = [...errors, VALIDATION_MESSAGES.GREATER_THAN_0];
      }

      if (!this.$v.dailyLimit.lessThanMonthlyLimit) {
        errors = [
          ...errors,
          VALIDATION_MESSAGES.DAILY_CANNOT_EXCEED_MONTHLY_LIMIT
        ];
      }

      return errors;
    },
    monthlyLimitErrors() {
      let errors = [];

      if (!this.$v.monthlyLimit.$dirty) {
        return [];
      }

      if (!this.$v.monthlyLimit.minValue) {
        errors = [...errors, VALIDATION_MESSAGES.GREATER_THAN_0];
      }

      if (!this.$v.monthlyLimit.lessThanCompanyMonthlySpending) {
        errors = [...errors, VALIDATION_MESSAGES.OVER_COMPANY_MONTHLY_SPENDING];
      }

      if (!this.$v.monthlyLimit.onlyDigits) {
        errors = [...errors, VALIDATION_MESSAGES.ONLY_DIGITS];
      }

      return errors;
    },

    ...mapState({
      activeCardIndex: state => state.cardPreferences.activeCardIndex,
      companyMonthlySpending: state => state.business.fuelAmount
    }),
    ...mapGetters({
      activeCard: 'cardPreferences/activeCard',
      numCards: 'cardPreferences/numCards',
      activeCardHasChanged: 'cardPreferences/activeCardHasChanged'
    }),
    cardTypeAnyValue: () => CARD_TYPE_ANY,
    cardTypeVehicleValue: () => CARD_TYPE_VEHICLE,
    cardTypeDriverValue: () => CARD_TYPE_DRIVER,
    cardTypeDriverVehicleValue: () => CARD_TYPE_DRIVER_VEHICLE,
    dailyLimitId: () => DAILY_LIMIT,
    monthlyLimitId: () => MONTHLY_LIMIT,
    vehicleRegistrationId: () => VEHICLE_REGISTRATION,
    driverVehicleRegistrationId: () => VEHICLE_REGISTRATION,
    driverNameId: () => DRIVER_NAME,
    driverVehicleNameId: () => DRIVER_NAME,
    brand: () => BRAND
  },
  watch: {
    async activeCardIndex(newVal, oldVal) {
      // Touch all the fields when opening the card
      if (newVal !== oldVal) {
        this.$v.dailyLimit.$touch();
        this.$v.monthlyLimit.$touch();
        this.$v.driverName.$touch();
        this.$v.vehicleRegistration.$touch();
        this.$v.driverVehicleName.$touch();
        this.$v.driverVehicleRegistration.$touch();
        await this.$nextTick();

        // Scroll to error, if no error, scroll to top
        if (this.$v.dailyLimit.$invalid) {
          this.$vuetify.goTo(`#${DAILY_LIMIT}`, {
            offset: navigationOffset(DAILY_LIMIT)
          });
        } else if (this.$v.monthlyLimit.$invalid) {
          this.$vuetify.goTo(`#${MONTHLY_LIMIT}`, {
            offset: navigationOffset(MONTHLY_LIMIT)
          });
        } else if (this.$v.driverName.$invalid) {
          this.$vuetify.goTo(`#${DRIVER_NAME}`, {
            offset: navigationOffset(DRIVER_NAME)
          });
        } else if (this.$v.vehicleRegistration.$invalid) {
          this.$vuetify.goTo(`#${VEHICLE_REGISTRATION}`, {
            offset: navigationOffset(VEHICLE_REGISTRATION)
          });
        } else if (this.$v.driverVehicleName.$invalid) {
          this.$vuetify.goTo(`#${DRIVER_NAME}`, {
            offset: navigationOffset(DRIVER_NAME)
          });
        } else if (this.$v.driverVehicleRegistration.$invalid) {
          this.$vuetify.goTo(`#${VEHICLE_REGISTRATION}`, {
            offset: navigationOffset(VEHICLE_REGISTRATION)
          });
        } else {
          this.$vuetify.goTo(0);
        }
      }
    }
  },
  methods: {
    labelNodes(string) {
      return string.split(' ');
    },
    isNodeLabelDEF(label) {
      return label === 'AdBlue';
    },
    onRemoveActiveCard() {
      this.$store.dispatch(
        'cardPreferences/changeCardIndexToRemove',
        this.activeCardIndex
      );
      this.$store.dispatch('ui/toggleRemoveCardDialogOpen', true);
    },
    onApplyToAll() {
      this.$store.dispatch('ui/toggleApplyToAllCardsDialogOpen', true);
    },
    onDiscardChanges() {
      this.$store.dispatch('ui/toggleDiscardActiveCardChangesDialogOpen', true);
    },
    onBack() {
      this.$store.dispatch('ui/toggleCardPreferencesSidebarOpen', true);
    },
    preventDecimalCharacter(event) {
      // prevents the `.` character from being pressed
      if (event.keyCode === DECIMAL_CHARACTER_CODE) {
        event.preventDefault();
      }
    }
  },
  validations() {
    return {
      driverName: {
        required: requiredIf(
          nestedModel => nestedModel.cardType === CARD_TYPE_DRIVER
        ),
        maxLength: maxLength(21)
      },
      vehicleRegistration: {
        required: requiredIf(
          nestedModel => nestedModel.cardType === CARD_TYPE_VEHICLE
        ),
        alphaNum,
        maxLength: maxLength(6)
      },
      driverVehicleName: {
        required: requiredIf(
          nestedModel => nestedModel.cardType === CARD_TYPE_DRIVER_VEHICLE
        ),
        maxLength: maxLength(21)
      },
      driverVehicleRegistration: {
        required: requiredIf(
          nestedModel => nestedModel.cardType === CARD_TYPE_DRIVER_VEHICLE
        ),
        alphaNum,
        maxLength: maxLength(6)
      },
      // these need to be duplicated in CardPreferencesSidebar.vue
      dailyLimit: {
        onlyDigits,
        minValue: minValue(1),
        lessThanMonthlyLimit: function(dailyValue, values) {
          if (!values.monthlyLimit || !dailyValue) {
            return true;
          }
          return Number(dailyValue) <= Number(values.monthlyLimit);
        }
      },
      // these need to be duplicated in CardPreferencesSidebar.vue
      monthlyLimit: {
        onlyDigits,
        lessThanCompanyMonthlySpending: function(monthlyValue, values) {
          return Number(monthlyValue) <= Number(values.companyMonthlySpending);
        },
        minValue: minValue(1)
      }
    };
  },
  created() {
    this.$store.dispatch('ui/toggleCardPreferencesSidebarOpen', true);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.preferences {
  position: relative;
  color: $charcoal;
  padding-top: rem(40px);

  @include sm {
    padding-top: rem(80px);
    padding-left: rem(60px);
  }

  @include md {
    padding-top: rem(40px);
  }

  @include lg {
    padding-left: 0;
  }

  @media (min-width: 1350px) {
    padding-left: rem(60px);
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    background-color: $white;

    &--sidebar-open {
      @include lg {
        left: $card-preferences-sidebar-width;
        width: calc(100% - #{$card-preferences-sidebar-width});
      }
    }
  }

  &__header-content {
    @include sm {
      padding-left: rem(76px);
    }

    @include md {
      padding-left: rem(84px);
    }

    @include lg {
      padding-left: 0;
    }

    @media (min-width: 1350px) {
      padding-left: rem(84px);
    }
  }
}

.group-label {
  margin-top: rem(20px);
}

.card-actions {
  display: flex;
  align-items: center;

  @include md {
    justify-content: flex-end;
  }

  @include xs-only {
    justify-content: center;
  }
  /deep/ > .text-button {
    margin: 0 auto; // center discard button on mobile

    @include sm {
      margin: rem(0 25px 0 0);
    }

    @include xs-only {
      margin: 0 10px !important;
    }
  }
}

.radio-group {
  margin-top: 0;
}

.preferences-title {
  color: #d2d2d2;
}

.back-button {
  position: absolute;
  top: 50%;
  left: rem(30px);
  transform: translate(0, -50%);
}
.fuel-type-required {
  color: $error-color;
}
</style>
