<template>
  <v-switch
    :value="value"
    :input-value="inputValue"
    :label="label"
    :hint="hint"
    :name="name"
    class="switch"
    persistent-hint
    @change="onChange"
  />
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  props: {
    value: {
      type: null,
      default: null
    },
    inputValue: {
      type: null,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.switch {
  margin: 0;
  padding: rem(23px 0 17px 0);
  border-bottom: 1px solid $light-charcoal;

  /deep/ .v-input__slot {
    margin-bottom: rem(3px);
  }

  /deep/ .v-messages {
    padding-left: rem(47px);
    font-size: rem(15px);
    color: $note-color;
  }

  /deep/ .v-label {
    color: $charcoal;
    font-size: rem(20px);
  }

  /deep/ .v-input--switch {
    &__track {
      background-color: transparent;
      border: 2px solid $mid-charcoal;
      opacity: 1;

      &.accent--text {
        border: 2px solid $bach-blue;
      }
    }

    &__thumb {
      box-shadow: none;
      background-color: $mid-charcoal;

      &.accent--text {
        background-color: $bach-blue;
      }
    }
  }
}
</style>
